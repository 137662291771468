const redirects = {
   'url1' : 'https://www.hjarnfonden.se/for-foretag/',
   'url2' : 'https://www.hjarnfonden.se/2021/02/vi-maste-satsa-mer-pa-forskning-om-psykisk-ohalsa/',
   'url3' : 'https://www.hjarnfonden.se/2021/02/ta-hand-om-dig-i-coronatider-sa-har-gor-du/',
   'url4' : 'https://www.hjarnfonden.se/2021/02/6-hjarnstarka-poddar-och-bocker/',
   'url5' : 'https://www.hjarnfonden.se/2021/02/darfor-haller-vanskap-hjarnan-frisk/',

   'url6' : 'https://www.hjarnfonden.se/foretagsvan_kontakt/',
   'url7' : 'https://www.hjarnfonden.se/foretagsvan_kontakt/',

   'url8' : 'https://www.hjarnfonden.se/2021/04/8-tips-for-att-stressa-lagom/',

   'url9' : { landing_page : 'https://www.hjarnfonden.se/stod-oss/foretag/julgava/?utm_source=webpower&utm_medium=email&utm_campaign=foretagjul_kall&utm_content=a&dynamics=9004003', name: "Julgåva 1" },
   'url10' : { landing_page : 'https://www.hjarnfonden.se/2024/09/genetiska-orsaker-bakom-migran-och-klusterhuvudvark/?utm_source=webpower&utm_medium=email&utm_campaign=foretagjul_kall&utm_content=1&dynamics=9004003', name: "Artikel: Migrän 1" },
   'url11' : { landing_page : 'https://www.hjarnfonden.se/2024/09/genetiska-orsaker-bakom-migran-och-klusterhuvudvark/?utm_source=webpower&utm_medium=email&utm_campaign=foretagjul_kall&utm_content=2&dynamics=9004003', name: "Artikel: Migrän 2" },
   'url12' : { landing_page : 'https://www.hjarnfonden.se/stod-oss/foretag/julgava/?utm_source=webpower&utm_medium=email&utm_campaign=foretagjul_kall&utm_content=b&dynamics=9004004', name: "Julgåva 2" },

   'url13' : { landing_page : 'https://www.hjarnfonden.se/stod-oss/foretag/julgava/?utm_source=webpower&utm_medium=email&utm_campaign=foretagjul_kall&dynamics=9004006', name: "Julgåva" },
   'url14' : { landing_page : 'https://www.hjarnfonden.se/2024/09/nar-alkoholen-kapar-hjarnan/?utm_source=webpower&utm_medium=email&utm_campaign=foretagjul_kall&dynamics=9004006', name: "Artikel: Alkohol" },

   'url15' : { landing_page : 'https://www.hjarnfonden.se/stod-oss/foretag/julgava/?utm_source=webpower&utm_medium=email&utm_campaign=foretagjul_kall&dynamics=9004011', name: "Julgåva" },

   'url16' : { landing_page : 'https://www.hjarnfonden.se/stod-oss/foretag/julgava/?utm_source=webpower&utm_medium=email&utm_campaign=foretagjul_kall&dynamics=9004011', name: "Julgåva - mejl 3 repeat" },

   'url17' : { landing_page : 'https://www.hjarnfonden.se/stod-oss/foretag/julgava/?utm_source=webpower&utm_medium=email&utm_campaign=foretagjul_kall&dynamics=9004012', name: "Julgåva - mejl 4" },

   'url18' : { landing_page : 'https://www.hjarnfonden.se/stod-oss/foretag/julgava/?utm_source=webpower&utm_medium=email&utm_campaign=foretagjul_kall&dynamics=9004016', name: "Julgåva - mejl 5" },

};

export default redirects